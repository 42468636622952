import React from 'react';
import { Modal } from "react-bootstrap";
import { toast } from 'react-toastify';

import { Images } from '../config';

const SupportEmailModal = (props) => {
    const charactersLimit = 140;
    const __copy = (template) => {

        try {
            let copyText = template;

            if (typeof (navigator.clipboard) === "object") {
                navigator.clipboard.writeText(copyText);

                /* Alert the copied text */
                toast.success("Copied")
            } else {
                /* Select the text field */
                copyText.select();
                copyText.setSelectionRange(0, 99999); /*For mobile devices*/

                /* Copy the text inside the text field */
                document.execCommand("copy");

                /* Alert the copied text */
                toast.success("Copied")
            }

        } catch (exception) {
            console.log(exception)
        }

    }
    const __copyTemplate = () => {
        __copy(props.emailTemplate)
        props.afterTemplateCopy();
    }


    return (<Modal show={props.isShow} className={"email-template-modal"}>
        <Modal.Body className="p-0">
            <div className="email-content">
                <button
                    type="button"
                    className="close"
                    onClick={() => props.onModalClose()}
                ><span aria-hidden="true">&times;</span>
                </button>
                <div>
                    <div className="text-center pb-3">
                        <img src={Images.tik} alt="" className="pb-1" />
                    </div>

                    <h5 className="title">Thank you for completing this poll.</h5>
                    <p className="sub-title px-4">
                        Based on your responses, we have created a draft email outlining your views on the project:
                    </p>
                    <div className="white-box mb-3" style={{ whiteSpace: 'pre-line' }}>
                        <div>
                            {
                                props.isEmailBodyTruncate && props.emailTemplate.length > charactersLimit ? props.emailTemplate.substring(0, charactersLimit) + '...' : props.emailTemplate
                            }
                        </div>
                        {
                            props.emailTemplate.length > charactersLimit &&
                            <div className="text-center" style={{ marginBottom: -15 }}>
                                <button className="btn btn-link font-weight-bold text-decoration-none pb-0" onClick={() => props.onEmailBodyTruncate()}>
                                    <small className="font-weight-bold">{props.isEmailBodyTruncate ? "See all" : "See less"}</small>
                                </button>
                            </div>

                        }
                    </div>
                    <div className="text-center">
                        {
                            props.isEmailTemplateCopied ?
                                <button className="btn btn-outline-primary btn-lg btn-copytoclipboard" onClick={() => __copyTemplate()}>
                                    <img src={Images.greentick} alt="" className="pr-3 pb-1" />
                                    Copied
                                </button>
                                :
                                <button className="btn btn-primary btn-lg btn-copytoclipboard" onClick={() => __copyTemplate()}>
                                    <img src={Images.emailcopy} alt="" className="pr-3 pb-1" />
                                    Copy to Clipboard
                                </button>
                        }


                        <h5 className="title pt-5">What's next?</h5>
                        <p className="content px-5">
                            We have recently submitted a planning application to your local
                            authority, which seeks permission to build what we are proposing.
                        </p>
                        <p className="content px-5">
                            If you would like to email your thoughts on the project to the
                            council, please click the button below to send an email directly to the case officer
                            assigned to consider the planning application.
                        </p>
                        <a className="btn btn-primary btn-lg" href={`mailto:${'gemma.perry@southwark.gov.uk'}?subject=${props.emailSubject}&bcc=${props.supportEmail}`}>Send email</a>
                        <div>
                            <button className="btn btn-link mt-2" onClick={() => props.onIsNotWorking()}>Not working?</button>
                            {props.isNotWorking &&
                                <p style={{ maxWidth: 480, margin: '0 auto' }}>
                                    If the button doesn’t work, please<br />manually<br />send your email to:
                                    <br />
                                    <span style={{ textDecoration: 'underline' }}>{props.supportEmail}</span>
                                </p>}
                        </div>
                    </div>
                </div>
            </div>
        </Modal.Body>
    </Modal>)
}

export default SupportEmailModal;